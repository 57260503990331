<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">Fica Bundle Details</h4>
            <v-simple-table v-if="$route.params.id != 0">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    {{ ficaProductModel.name ? ficaProductModel.name : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>
                    <v-chip label dark>{{
                      ficaProductModel.description
                    }}</v-chip>
                  </td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td>
                    {{
                      ficaProductModel.category ? ficaProductModel.category : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-btn
              v-if="$route.params.id == 0"
              color="primary"
              :to="`${$route.path}/edit`"
              class="mr-2"
              >Create a New Fica Bundle</v-btn
            >
            <v-btn
              v-else
              color="primary"
              :to="`${$route.path}/edit`"
              class="mr-2"
              >Edit</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      isEdit: false,
      isLoading: false,

      me: "",
      search: null,
      crumbs: [
        {
          text: "Fica Bundles",
          disabled: false,
          href: "/admin/ficaproducts",
        },
        {
          text: "Fica Bundle Details",
          disabled: true,
          href: `/admin/ficaproducts/${this.$route.params.id}`,
        },
      ],
    };
  },
  created() {},
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    ficaProduct: {
      query: gql`
        query FicaProduct($id: ID!) {
          ficaProduct(id: $id) {
            id
            name
            description
            category
            createdBy
            createdAt
            updatedAt
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.$route.params.id || this.$route.params.id == 0;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        this.isLoading = false;
        this.ficaProductModel.id = this.ficaProduct.id;
        this.ficaProductModel.name = this.ficaProduct.name;
        this.ficaProductModel.description = this.ficaProduct.description;
        this.ficaProductModel.category = this.ficaProduct.category;
        this.ficaProductModel.createdBy = this.ficaProduct.createdBy;
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
